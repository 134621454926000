<template>
  <section>
    <v-text-field
      v-model="search"
      append-icon="mdi-magnify"
      label="Szukaj"
      single-line
      hide-details
      class="mb-3 elevation-1 pa-3"
      clearable
    ></v-text-field>
    <v-data-table
      :headers="headers"
      :items="logs"
      :search="search"
      :footer-props="{
        'items-per-page-text': 'Logów na stronę',
        'items-per-page-options': [10, 20, 50, 100],
      }"
      :items-per-page="20"
      class="elevation-2"
      multi-sort
    >
      <template v-slot:item="{ item }">
        <tr>
          <td>{{ item.datetime_created }}</td>
          <td>{{ item.action }}</td>
          <td>{{ item.author_name }}</td>
        </tr>
      </template>

      <template v-slot:footer.page-text="items">
        {{ items.pageStart }}-{{ items.pageStop }} z
        {{ items.itemsLength }}
      </template>
    </v-data-table>
  </section>
</template>

<script>
export default {
  data() {
    return {
      search: "",
      headers: [
        { text: "Data", value: "datetime_created" },
        { text: "Akcja", value: "action" },
        { text: "Użytkownik", value: "author_name" },
      ],
    };
  },
  props: {
    logs: {
      type: Array,
      required: true,
    },
    logsTableLoading: {
      type: Boolean,
      required: true,
    },
  },
};
</script>