<template>
  <section class="cms-logs">
    <CMSNavTop :apiLink="apiLink" />
    <CMSNavSidebar />
    <div style="padding-left: 56px">
      <Alert
        :alertShow="alertShow"
        :alertText="alertText"
        :alertType="alertType"
        @closeAlert="closeAlert"
      />
      <v-container fluid>
        <v-row>
          <v-col>
            <LogsTable :logs="logs" :logsTableLoading="logsTableLoading" />
          </v-col>
        </v-row>
      </v-container>
    </div>
  </section>
</template>

<script>
import CMSNavTop from "@/components/CMS/Navigations/CMSNavTop.vue";
import CMSNavSidebar from "@/components/CMS/Navigations/CMSNavSidebar.vue";
import Alert from "@/components/Alert.vue";
import LogsTable from "@/components/CMS/Logs/LogsTable.vue";

export default {
  name: "CMSLogs",
  components: {
    CMSNavTop,
    CMSNavSidebar,
    Alert,
    LogsTable,
  },
  props: {
    apiLink: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      alertText: "",
      alertType: "success",
      alertShow: false,
      logs: [],
      logsTableLoading: false,
    };
  },
  created() {
    this.getLogs();
  },
  methods: {
    showAlert(alertText, alertType) {
      this.alertShow = true;
      this.alertText = alertText;
      this.alertType = alertType;
      console.log(alertText);
    },
    closeAlert() {
      this.alertShow = false;
    },
    async getLogs() {
      this.logsTableLoading = true;
      await this.axios({
        method: "post",
        url: `${this.apiLink}/api/views/logs/getLogs.php`,
        data: {
          token: localStorage.token,
        },
      })
        .then((res) => {
          if (res.status === 200) {
            this.logs = res.data;
          } else {
            this.showAlert(
              `Błąd podczas pobierania produktów. Kod błędu API: ${res.status}`,
              "error"
            );
          }
        })
        .catch((err) => {
          this.showAlert(`Błąd pobierania produktów. Błąd: ${err}`, "error");
        });
      this.logsTableLoading = false;
    },
  },
};
</script>